import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Trail, Spring, animated, config } from 'react-spring'

import Layout from '../components/layout'
import WindowSize from '@reach/window-size'
import our_logo from '../images/logo.svg'
import { Row, Column } from '../components/grid'
import Headline from '../components/headline'
import Logo from '../components/logo'
import Container from '../components/container'
// import Lead from '../components/lead'

const Center = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    padding-top: 50px;
  }
`

const sentence =
  'Our site doesn’t have a lot to show... but we do do great work.'

const items = sentence.split(' ').map((char, index) => ({ key: index, char }))

const Lead = styled.h2`
  margin: 0;
  color: #fff;
  font-weight: 700;

  font-size: 22px;
  line-height: 23px;
  font-weight: 700;

  @media (min-width: 375px) {
    font-size: 26px;
    line-height: 27px;
  }
  @media (min-width: 410px) {
    font-size: 32px;
    line-height: 34px;
  }
  @media (min-width: 768px) {
    font-size: 42px;
    line-height: 1.2;
    margin-bottom: 70px;
  }
  font-family: 'Playfair Display', serif;
  font-weight: bold;
`

const btn_line = keyframes`
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
`

const InlineLink = styled.a`
  position: relative;
  color: #fff;
  text-decoration: none;
  &:after {
    content: '';
    display: block;
    width: 0%;
    height: 5px;
    position: absolute;
    left: 0;
    bottom: -10px;
    background-color: #e41352;
    animation: ${btn_line};
    animation-delay: 3s;
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-fill-mode: cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`

const Address = styled.div`
  font-size: 12px;
  color: #666;
  padding: 30px 0;
`

const PresentBoxPage = () => (
  <Layout>
    <WindowSize>
      {size => (
        <Container h={size.height}>
          <Center>
            <Row>
              <Column s={10} m={10} l={7} xl={5} lo={1}>
                <Logo
                  src={our_logo}
                  alt="Hustle & Praise"
                  title="Hustle & Praise"
                />
                <Headline className="alt">
                  <Trail
                    items={items}
                    keys={item => item.key}
                    from={{ transform: 'translateY(-20px)', opacity: 0 }}
                    to={{ transform: 'translateY(0px)', opacity: 1 }}
                    config={config.gentle}
                  >
                    {item => props => (
                      <animated.span
                        style={{
                          display: 'inline-block',
                          marginRight: '10px',
                          ...props,
                        }}
                      >
                        {item.char}
                      </animated.span>
                    )}
                  </Trail>
                </Headline>
              </Column>
            </Row>
            <Row>
              <Column s={10} m={10} l={6} xl={5} lo={1}>
                <Spring
                  from={{ transform: 'translateY(-20px)', opacity: 0 }}
                  to={{ transform: 'translateY(0px)', opacity: 1 }}
                  delay={2750}
                  config={{ duration: 800, ...config.gentle }}
                >
                  {props => (
                    <Lead className="alt" style={props}>
                      If you’d like to find out how we helped{' '}
                      <span style={{ color: '#e41352' }}>The Present Box</span>,{' '}
                      The Irish Fairy Door Company, Millies and others say hello{' '}
                      <InlineLink href="mailto:studio@hustleandpraise.com?subject=Tell%20me%20more%20about%20your%20work.&body=Be%20sure%20to%20include%20a%20phone%20number!">
                        here.
                      </InlineLink>
                    </Lead>
                  )}
                </Spring>
              </Column>
            </Row>
            <Row>
              <Column s={10} m={10} l={6} xl={5} lo={1}>
                <Address>
                  76 Thomas Street, Dublin 8, Ireland &bull; +353 (0)1 905 3953
                  &bull; studio@hustleandpraise.com
                </Address>
              </Column>
            </Row>
          </Center>
        </Container>
      )}
    </WindowSize>
  </Layout>
)

export default PresentBoxPage
